
// @ts-ignore
import { nanoid ,teamNanoid ,userNanoid } from '../../../brands.js'
// @ts-ignore
import { taxQuestionnaireValues } from '../../../formats.js'
// @ts-ignore

// @ts-ignore

import { z } from 'zod'

export const employee_tax_questionnaires = z.object({
	id: z.number(),
	country_code: z.string().trim().optional().default('US'),
	nanoid: userNanoid,
	team_nanoid: teamNanoid,
	questionnaire_values: taxQuestionnaireValues,
	dependents_count: z.number().optional().default(0),
	created_at: z.union([z.number(), z.string(), z.date()]).pipe(z.coerce.date()).optional(),
})

export const insertable_employee_tax_questionnaires = z.object({
  country_code: z.string().trim().optional().default('US'),
  nanoid: userNanoid,
  team_nanoid: teamNanoid,
  questionnaire_values: taxQuestionnaireValues,
  dependents_count: z.number().optional().default(0),
})

export const updateable_employee_tax_questionnaires = z.object({
  country_code: z.string().trim().optional().default('US'),
  nanoid: userNanoid.optional(),
  team_nanoid: teamNanoid.optional(),
  questionnaire_values: taxQuestionnaireValues.optional(),
  dependents_count: z.number().optional().default(0),
})

export const selectable_employee_tax_questionnaires = z.object({
  id: z.number(),
  country_code: z.string(),
  nanoid: userNanoid,
  team_nanoid: teamNanoid,
  questionnaire_values: taxQuestionnaireValues,
  dependents_count: z.number(),
  created_at: z.union([z.number(), z.string(), z.date()]).pipe(z.coerce.date()),
})

export type EmployeeTaxQuestionnairesType = z.infer<typeof employee_tax_questionnaires>
export type InsertableEmployeeTaxQuestionnairesType = z.infer<typeof insertable_employee_tax_questionnaires>
export type UpdateableEmployeeTaxQuestionnairesType = z.infer<typeof updateable_employee_tax_questionnaires>
export type SelectableEmployeeTaxQuestionnairesType = z.infer<typeof selectable_employee_tax_questionnaires>
