import { z } from 'zod'
import { teamNanoid } from '../../brands.js'
import {
  insertable_employee_tax_questionnaires,
  selectable_employee_tax_questionnaires,
} from '../../codegen/zod/rise/employee_tax_questionnaires.js'
import { recaptcha } from '../../formats.js'
import { defaultErrorResponses, defaultResponse } from '../../response.js'
import type { Schema } from '../../types.js'
import { isNumeric } from '../../validations.js'

export const schema = {
  '/compliance/tax_questionnaires': {
    post: {
      tags: ['Compliance'],
      security: [{ bearerAuth: [] }],
      headers: recaptcha,
      summary: 'Insert/update the user tax questionnaire',
      body: insertable_employee_tax_questionnaires.refine(
        (q) =>
          !q.questionnaire_values.some(
            (qv) => qv.parameter_type === 'DOLLAR' && !isNumeric(qv.value),
          ),
        {
          message: 'Invalid Dollar value',
        },
      ),
      response: {
        200: defaultResponse,
        ...defaultErrorResponses,
      },
    },
    get: {
      tags: ['Compliance'],
      security: [{ bearerAuth: [] }],
      summary: 'Get the user tax questionnaire',
      querystring: z.object({
        team_nanoid: teamNanoid,
      }),
      response: {
        200: defaultResponse.extend({
          data: selectable_employee_tax_questionnaires.omit({
            created_at: true,
          }),
        }),
        ...defaultErrorResponses,
      },
    },
  },
} as const satisfies Schema
