import { z } from 'zod'
import { teamNanoid } from '../../brands.js'
import { defaultErrorResponses, defaultResponse } from '../../response.js'
import type { Schema } from '../../types.js'

const wageTypes = z.enum([
  'Regular',
  'Supplemental',
  'PreviousPeriod',
  'PreviousPeriodSupplemental',
  'Combined',
  'CanadaLumpsum',
  'CanadaBonus',
  'FUTAGross',
  'SUTAGross',
  'SUTAPreviousState',
  'SUTAPredecessor',
  'CanadaEI',
  'CanadaEI_Bonus',
  'CanadaCPP',
  'CanadaCPP_Bonus',
  'CanadaQPP',
  'CanadaQPP_Bonus',
  'CanadaPensionIncome',
  'CanadaQuebecSinglePayment',
  'CanadaQPIP',
  'TIPS',
  'RUIA_Compensation',
  'Custom',
  'CanadaCommission',
  'CanadaWorkersCompensation',
  'CanadaGroupBenefits',
  'CanadaHealthServiceFund',
  'Overtime',
  'CanadaQPIP_Bonus',
])

export type WageTypes = z.infer<typeof wageTypes>

const calcMethodRegularWagesTypes = z.enum([
  'Annualized',
  'Cumulative',
  'Flat',
  'CurrentAggregation',
  'PreviousAggregation',
  'Daily',
  'None',
])

export type CalcMethodRegularWagesTypes = z.infer<
  typeof calcMethodRegularWagesTypes
>

/*const responseWageTypes = z.enum([
	'REGULAR',
	'SUPPLEMENTAL',
	'PREVIOUS_PERIOD',
	'PREVIOUS_PERIOD_SUPPLEMENTAL',
	'COMBINED',
	'CANADA_LUMPSUM',
	'CANADA_BONUS',
	'FUTA_GROSS',
	'SUTA_GROSS',
	'SUTA_PREVIOUS_STATE',
	'SUTA_PREDECESSOR',
	'CANADA_EI',
	'CANADA_EI_BONUS',
	'CANADA_CPP',
	'CANADA_CPP_BONUS',
	'CANADA_QPP',
	'CANADA_QPP_BONUS',
	'CANADA_PENSION_INCOME',
	'CANADA_QUEBEC_SINGLE_PAYMENT',
	'CANADA_QPIP',
	'TIPS',
	'RUIA_COMPENSATION',
])*/

/*const locationTaxesBodySchema = z.object({
	locationCodeFull: z.string(),
	payDate: z.string().regex(/^\d{4}-\d{2}-\d{2}$/),
})*/

const locationTaxesResponseSchema = z.object({
  dependents_count: z.number(),
  items: z
    .object({
      uniqueTaxID: z.string(),
      description: z.string(),
      isEmployerTax: z.boolean(),
      jurisdictionData: z
        .object({
          parameterName: z.string(),
          description: z.string(),
          dataType: z.string(),
          regexType: z.string(),
          defaultValue: z.string(),
          isOptional: z.boolean(),
          regex: z.string(),
          currentValue: z.string().optional(),
          uniqueTaxID: z.string(),
          helpText: z.string(),
        })
        .partial()
        .array()
        .optional(),
    })
    .partial()
    .array(),
})

export type LocationTaxesResponseSchema = z.infer<
  typeof locationTaxesResponseSchema
>

/*const payCalcBodySchema = z.object({
	options: z.object({
		prorate: z.boolean(),
	}),
	employeeID: z.string(),
	payrollRunParameters: z.object({
		payDate: z.string().regex(/^\d{4}-\d{2}-\d{2}$/),
		payPeriodsPerYear: z.number(),
		payPeriodNumber: z.number(),
	}),
	wages: z
		.object({
			locationCode: z.string(),
			wageType: wageTypes,
			calcMethodRegularWages: calcMethodRegularWagesTypes,
			calcMethodSupplementalWages: calcMethodRegularWagesTypes,
			hours: z.number(),
			hasNexus: z.boolean(),
			grossWages: z.number(),
			mtdWages: z.number(),
			qtdWages: z.number(),
			ytdWages: z.number(),
		})
		.array(),
	jurisdiction: z
		.object({
			uniqueTaxID: z.string(),
			locationCode: z.string(),
			isExempt: z.boolean(),
			isResident: z.boolean(),
			periodWH: z.number(),
			mtdWH: z.number(),
			qtdWH: z.number(),
			ytdWH: z.number(),
			roundResult: z.enum(['No', 'Yes']),
			autoAdjust: z.boolean(),
			overrideRate: z.number(),
			overrideWageBase: z.number(),
			miscParameters: z
				.object({
					parmName: z.string(),
					parmValue: z.string(),
				})
				.array(),
		})
		.array(),
})*/

/*const benefitTypes = z.enum([
	'BENEFIT_125',
	'BENEFIT_401_K',
	'BENEFIT_403_B',
	'BENEFIT_457',
	'BENEFIT_CUSTOM',
	'BENEFIT_ROTH_401_K',
	'BENEFIT_ROTH_403_B',
	'BENEFIT_FSA',
	'BENEFIT_HSA',
	'BENEFIT_SIMPLE_IRA',
	'BENEFIT_ROTH_457',
	'BENEFIT_FSA_DEPENDENT_CARE',
	'BENEFIT_SIMPLE_IRA_NONELECTIVE',
])*/

/*const payCalcResponseSchema = z
	.object({
		employeeID: z.string(),
		benefitCalculation: z
			.object({
				benefitType: benefitTypes,
				benefitReferenceCode: z.string(),
				benefitAmt: z.number(),
				benefitAmtYTD: z.number(),
				annualLimit: z.number(),
				wageType: responseWageTypes,
				isCatchUp: z.boolean(),
				calculatedAmt: z.number(),
				employerCalculatedAmt: z.number(),
				employerContribution: z.number(),
				employerContributionYTD: z.number(),
			})
			.partial()
			.array(),
		taxCalculation: z
			.object({
				locationCode: z.string(),
				uniqueTaxID: z.string(),
				isResident: z.boolean(),
				wageType: responseWageTypes,
				description: z.string(),
				taxAmount: z.number(),
				grossWages: z.number(),
				subjectWages: z.number(),
				grossSubjectWages: z.number(),
				isUnemploymentTax: z.boolean(),
				rate: z.number(),
				wageBase: z.number(),
				subjectWagesCTD: z.number(),
				excessWagesCTD: z.number(),
				subjectWagesQTD: z.number(),
				excessWagesQTD: z.number(),
				subjectWagesYTD: z.number(),
				excessWagesYTD: z.number(),
			})
			.partial()
			.array(),
	})
	.partial()
	.array()
*/

export const schema = {
  '/compliance/taxes/location_taxes': {
    get: {
      tags: ['Compliance'],
      security: [{ bearerAuth: [] }],
      summary: 'Gets the location taxes for an address',
      querystring: z.object({
        team_nanoid: teamNanoid,
      }),
      response: {
        200: defaultResponse.extend({
          data: locationTaxesResponseSchema,
        }),
        ...defaultErrorResponses,
      },
    },
  },
} as const satisfies Schema
