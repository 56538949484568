import { defineStore } from "pinia";
import { z } from "zod";
import { schema } from "~/.nuxt/contracts/src/routes/compliance/taxes";
import { schema as questionnaireSchema } from "~/.nuxt/contracts/src/routes/compliance/tax_questionnaires";

const locationTaxesResponse =
  schema["/compliance/taxes/location_taxes"].get.response[200].shape.data;
const taxQuestionnaireResponse =
  questionnaireSchema["/compliance/tax_questionnaires"].get.response[200].shape
    .data;

export type LocationTax = z.infer<typeof locationTaxesResponse.element>;
type TaxQuestionnaireResponse = z.infer<typeof taxQuestionnaireResponse>;
export type TaxQuestionnaire =
  TaxQuestionnaireResponse["questionnaire_values"][number];

export const useEmployeePayrollStore = defineStore("employee_payroll", {
  state: (): {} => ({}),
  actions: {
    async getLocationTaxes(team_nanoid: string) {
      const response = await this.$client[
        "/compliance/taxes/location_taxes"
      ].get({
        query: {
          team_nanoid,
        },
      });
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      const responseJson = await response.json();
      return responseJson.data;
    },

    async updateTaxAnswers(
      dependents_count: number,
      nanoid: string,
      team_nanoid: string,
      questionnaire_values: TaxQuestionnaire[]
    ) {
      const response = await this.$client[
        "/compliance/tax_questionnaires"
      ].post({
        json: {
          dependents_count,
          nanoid,
          team_nanoid,
          questionnaire_values,
        },
      });
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      const responseJson = await response.json();
      return responseJson;
    },

    async checkIfEmployeeIsEnrolled(team_nanoid: string) {
      const response = await this.$client[
        `/dashboard/payroll/teams/${team_nanoid}/is_enrolled`
      ].get();

      if (!response.ok) {
        throw new Error(response.statusText);
      }

      const responseJson = await response.json();
      return responseJson.data;
    },
  },
});
